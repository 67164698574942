import React from 'react';
import { XAxis, Tooltip, ResponsiveContainer, LineChart, Line, LabelList } from 'recharts';
import { InBodyEntry } from '../../../types';
import styles from './styles.module.scss';

interface HistoryProps {
  data: InBodyEntry[];
}

type BarDataItem = { date: string; Gewicht: string; Skelettmuskelmasse: string; Körperfettmasse: string };

const formatDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.');
  return `${Number(day) >= 10 || day.startsWith('0') ? day : `0${day}`}.${
    Number(month) >= 10 || month.startsWith('0') ? month : `0${month}`
  }.`; // Return formatted date as DD.MM.
};

const History: React.FC<HistoryProps> = ({ data }) => {
  const barData: BarDataItem[] = data
    .map(entry => {
      return {
        date: formatDate(entry.date),
        Gewicht: entry.inBodyData.Weight.toString(),
        Skelettmuskelmasse: entry.inBodyData.SMM_SkeletalMuscleMass.toString(),
        Körperfettmasse: entry.inBodyData.BFM_BodyFatMass.toString(),
      };
    })
    .slice(0, 4)
    .reverse();

  return (
    <div className="mt-50">
      <div className={styles.chart}>
        <p>Gewicht</p>
        <ResponsiveContainer width="100%" height="100%" aspect={14 / 2}>
          <LineChart data={barData} margin={{ top: 3, right: 30, left: 30, bottom: 5 }}>
            <Tooltip wrapperStyle={{ color: '#000' }} />

            <Line dataKey="Gewicht" stroke="#FBA500" isAnimationActive={false}>
              <LabelList dataKey="Gewicht" position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" hide />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p>Skelettmuskelmasse</p>
        <ResponsiveContainer width="100%" aspect={14 / 2}>
          <LineChart data={barData} margin={{ top: 3, right: 30, left: 30, bottom: 5 }}>
            <Tooltip wrapperStyle={{ color: '#000' }} />

            <Line dataKey="Skelettmuskelmasse" stroke="#FBA500" isAnimationActive={false}>
              <LabelList dataKey="Skelettmuskelmasse" position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" hide />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p>Körperfettmasse</p>
        <ResponsiveContainer width="100%" aspect={14 / 3.5}>
          <LineChart data={barData} margin={{ top: 3, right: 30, left: 30, bottom: 5 }}>
            <Tooltip wrapperStyle={{ color: '#000' }} />

            <Line dataKey="Körperfettmasse" stroke="#FBA500" isAnimationActive={false}>
              <LabelList dataKey="Körperfettmasse" position="bottom" className={styles.strokeLabel} />
            </Line>
            <XAxis dataKey="date" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default History;
